/**
 * @generated SignedSource<<cb7b51ecfe9f689883a56762eb654f40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedItemTile_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_user">;
  readonly " $fragmentType": "SharedItemTile_user";
};
export type SharedItemTile_user$key = {
  readonly " $data"?: SharedItemTile_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedItemTile_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItemProvider_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "fe5be0dd7864bf70302a67723ff8b4b7";

export default node;
