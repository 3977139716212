import { MEASUREMENTS } from '../finding/SbSharedRefineMenu/sbSharedRefineMenuConstants';
import { SAVE_PREFERENCE } from '../actions/displayPreferenceActions';

const initialState = {
    showMeasurements: false,
};

export function displayPreferenceReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SAVE_PREFERENCE:
            if (payload.name === MEASUREMENTS) {
                return {
                    ...state,
                    showMeasurements: !!payload.value,
                };
            } else {
                return state;
            }
        default:
            return state;
    }
}
