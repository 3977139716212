import serverVars from 'server-vars';
import { getMeasurementUnit } from 'dibs-intl/exports/units';
import { UPDATE_MEASUREMENT_UNIT } from '../actions/measurementUnitActions';

export function measurementUnitReducer(state, action) {
    const { type } = action;
    const locale = serverVars.get('locale');

    switch (type) {
        case UPDATE_MEASUREMENT_UNIT:
            return action.payload;
        default:
            return getMeasurementUnit(locale);
    }
}
