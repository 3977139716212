import { decode64 } from '../utils/decode';

export const TOGGLE_FILTER_DISPLAY = 'TOGGLE_FILTER_DISPLAY';
export function toggleFilterDisplay(shown) {
    return { type: TOGGLE_FILTER_DISPLAY, filtersShown: shown };
}

export const TOGGLE_REQUEST_IN_FLIGHT = 'TOGGLE_REQUEST_IN_FLIGHT';
export function toggleRequestInFlight(payload) {
    return { type: TOGGLE_REQUEST_IN_FLIGHT, payload };
}

export const UPDATE_URI_REF = 'UPDATE_URI_REF';
export function updateUriRef({
    filterName,
    filterValue,
    uriRef,
    isFromTypeahead = false,
    isDeselected = false,
    ga,
    event,
}) {
    if (!uriRef) {
        const { linkReference } = filterValue;
        uriRef = linkReference && decode64(linkReference);
    }

    return {
        type: UPDATE_URI_REF,
        payload: {
            uriRef,
            filterName,
            filterValue,
            isFromTypeahead,
            isDeselected,
            ga,
            event, // actionSanitizer function from src/reducers/reducerUtils.js could be deleted when event is no longer used
        },
    };
}

export const SET_TRIGGER_TYPE = 'SET_TRIGGER_TYPE';
export function setTriggerType({ triggerType }) {
    return { type: SET_TRIGGER_TYPE, payload: triggerType };
}

export const UPDATE_ZIP_CODE = 'UPDATE_ZIP_CODE';
export function updateZipCode(zipCode) {
    return { type: UPDATE_ZIP_CODE, payload: zipCode };
}
