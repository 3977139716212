import { localStorage } from 'dibs-browser-storage';
import Cookies from 'js-cookie';
import {
    MEASUREMENT_STORAGE_KEY,
    SOLD_STORAGE_KEY,
    MEASUREMENTS,
    SOLD,
} from '../finding/SbSharedRefineMenu/sbSharedRefineMenuConstants';

export const SAVE_PREFERENCE = 'SAVE_PREFERENCE';
export function savePreference(name, value) {
    // if exclude sold listing then set cookie so we can persist
    // (has to be cookie so the CDN can read and apply the right query param)
    if (name === SOLD) {
        if (value) {
            Cookies.set(SOLD_STORAGE_KEY, false);
        } else {
            Cookies.remove(SOLD_STORAGE_KEY);
        }
    } else if (name === MEASUREMENTS) {
        // if measurement filter then set localStorage so we can persist
        if (value) {
            localStorage.setItem(MEASUREMENT_STORAGE_KEY, true);
        } else {
            localStorage.deleteItem(MEASUREMENT_STORAGE_KEY);
        }
    }

    return { type: SAVE_PREFERENCE, payload: { name, value } };
}

export function setPreferenceValueFromLocalStorage(name) {
    let value = false;
    if (name === MEASUREMENTS) {
        value = localStorage.getItem(MEASUREMENT_STORAGE_KEY);
    }
    return { type: SAVE_PREFERENCE, payload: { name, value } };
}
