import {
    TOGGLE_FILTER_DISPLAY,
    TOGGLE_REQUEST_IN_FLIGHT,
    UPDATE_URI_REF,
    UPDATE_ZIP_CODE,
    SET_TRIGGER_TYPE,
} from '../actions/filterActions';
import { getQueryParam, addCdnQueryParams } from '../utils/uriUtils';
import { trackFilterChange } from '../utils/tracking/searchBrowse/filterTracking';
const getPage = generatedUriRef =>
    parseInt(getQueryParam(generatedUriRef, 'page'), 10) ||
    parseInt(getQueryParam(generatedUriRef, 'page', true), 10) ||
    1;

const initialState = {
    filtersShown: false,
    generatedUriRef: null,
    page: 1,
    refetchInFlight: false,
    zipCode: '0',
    userZipCode: '0',
    hasNewFilterUpdates: false,
    triggerType: null,
};

export function filterReducer(state = initialState, action) {
    const { type, payload } = action;

    // if a request is in flight, the state is temporarily locked until it completes :
    if (state.refetchInFlight && type !== TOGGLE_REQUEST_IN_FLIGHT) {
        return state;
    }

    switch (type) {
        case TOGGLE_REQUEST_IN_FLIGHT:
            return { ...state, refetchInFlight: payload, hasNewFilterUpdates: true };

        case UPDATE_ZIP_CODE: {
            return {
                ...state,
                ...{ userZipCode: payload, fetchRegionalInfo: true, hasNewFilterUpdates: true },
            };
        }

        case TOGGLE_FILTER_DISPLAY: {
            const filtersShown =
                typeof action.filtersShown === 'boolean'
                    ? action.filtersShown
                    : !state.filtersShown;
            return { ...state, filtersShown, hasNewFilterUpdates: false };
        }

        case SET_TRIGGER_TYPE: {
            return { ...state, triggerType: payload };
        }

        case UPDATE_URI_REF: {
            const {
                uriRef: generatedUriRef,
                filterName,
                filterValue,
                isFromTypeahead,
                isDeselected,
                ga,
                event,
            } = payload || {};
            const newUriRef = generatedUriRef
                ? addCdnQueryParams(generatedUriRef)
                : state.generatedUriRef;
            trackFilterChange({
                filterName,
                filterValue,
                isFromTypeahead,
                isDeselected,
                triggerType: state.triggerType,
                ga,
                event,
            });
            return {
                ...state,
                generatedUriRef: newUriRef,
                page: getPage(newUriRef),
            };
        }

        default:
            return state;
    }
}
