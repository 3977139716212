import { UPDATE_RING_MEASUREMENT_TYPE } from '../actions/ringMeasurementTypeActions';
const initialState = '';

export function ringMeasurementTypeReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case UPDATE_RING_MEASUREMENT_TYPE:
            return action.payload;
        default:
            return state;
    }
}
