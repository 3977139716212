import { filterReducer } from './filterReducer';
import { displayPreferenceReducer } from './displayPreferenceReducer';
import { measurementUnitReducer } from './measurementUnitReducer';
import { ringMeasurementTypeReducer } from './ringMeasurementTypeReducer';

export const sbSharedReducers = {
    filters: filterReducer,
    displayPreferences: displayPreferenceReducer,
    measurementUnit: measurementUnitReducer,
    ringMeasurementType: ringMeasurementTypeReducer,
};
