/**
 * @generated SignedSource<<3907d1776fc2f1e4cb885ae429c6f514>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedItemTile_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileCarousel_viewer">;
  readonly " $fragmentType": "SharedItemTile_viewer";
};
export type SharedItemTile_viewer$key = {
  readonly " $data"?: SharedItemTile_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchRegionalInfo"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedItemTile_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fetchRegionalInfo",
          "variableName": "fetchRegionalInfo"
        },
        {
          "kind": "Variable",
          "name": "userCountryCode",
          "variableName": "userCountryCode"
        },
        {
          "kind": "Variable",
          "name": "userZipCode",
          "variableName": "userZipCode"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemTileCarousel_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "1e03313379129888e41716bf223f0980";

export default node;
